import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import createLogger from 'debug';

const log = createLogger('query:BaasProviderCurrencies:cache');
/**
 * Сбросить кэш доступных валют
 */
export const useDropBaasProviderCurrenciesCache = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient
      .invalidateQueries(['BaasProviderCurrencies'])
      .then(() => log('BaasProviderCurrencies cache dropped'));
  }, [queryClient]);
};
