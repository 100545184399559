export const transfers = {
  recipientInfo: 'Recipient info',
  senderInfo: 'Sender info',
  transferInfo: 'Transfer info',
  date: 'Date',
  sender: 'Sender',
  senderAccount: 'Sender account',
  receiverAccount: 'Receiver account',
  recipient: 'Recipient',
  iban: 'IBAN',
  accountNumber: 'Account number',
  bic: 'BIC',
  bankName: 'Bank name',
  bankCountry: 'Bank country',
  address: 'Address',
  amount: 'Amount',
  amountCredited: 'Amount Credited',
  amountDebited: 'Amount Debited',
  fee: 'Fee',
  totalAmount: 'Total amount',
  currency: 'Currency',
  transferId: 'Transfer ID',
  type: 'Type',
  system: 'System',
  description: 'Description',
  status: 'Status',
  statuses: {
    completed: 'Completed',
    processing: 'Processing',
    failed: 'Failed',
    technicalProblem: 'Technical Problem',
  },
  incoming: 'Incoming',
  outgoing: 'Outgoing',
  all: 'All',
  chargeAccount: 'Charge account',
  depositAccount: 'Deposit account',
  exchangeRate: 'Exchange rate',
  senderCountry: 'Sender Country',
  repeat: 'Repeat transfer',
  reason: 'Reason',
  attachedDocuments: 'Attached documents',
  mappedSystems: {
    sepa: 'SEPA',
    swift: 'SWIFT',
    internal: 'Internal',
    self: 'Self-transfers'
  },
  mappedStatuses: {
    processing: 'Processing',
    completed: 'Completed',
    failed: 'Failed',
    technicalProblem: 'Technical Problem',
  },
  mappedTypes: {
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    fx: 'Exchange',
  },
  transferToSelf: 'Transfer to self',
};
