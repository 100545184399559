import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCountdownTimer,
  useGetAxiosError,
  useHandleFormError,
  useHandleToastError,
} from '@payler/bank-utils';
import { ApiErrorText, OtpField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { Trans, useTranslation } from 'react-i18next';
import {
  BankModalBody,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAddAccountConfirmOTPMutation,
  useAddAccountResendOTPMutation,
} from '../../hooks/accounts/mutations';
import {
  TAddAccountFailInfo,
  useAddAccountWizardContext,
} from './AddAccountWizard';
import { useBankAccountsSettingsQuery } from '../../hooks/configurations/queries';
import { useClientOfficeConfig } from '../../config/ConfigProvider';

type TAddAccountOTPForm = {
  code: string;
};

const EXPIRATION_TIME = 180_000;
const RESEND_CODE_TIME = 60_000;

// Код ошибки, когда сессия подтверждения OTP завершена, и продолжить создание не возможно
const OTP_EXPIRED_CODE = 'OTP_EXPIRED';
// Код ошибки, когда отправка OTP попала в защиту от спама
const OTP_LOCKED_CODE = 'OTP_LOCKED';

const useResolver = () => {
  const { t } = useTranslation();

  return yupResolver(
    yup.object().shape({
      code: yup
        .string()
        .required(t('validate.required'))
        .typeError(t('validate.required')),
    }),
  );
};

const errorsFieldsMap: Record<string, keyof TAddAccountOTPForm> = {
  OtpCode: 'code',
};

export const AddAccountOTPForm = () => {
  const { t } = useTranslation();
  const { data: settings } = useBankAccountsSettingsQuery();
  const { otpLength } = useClientOfficeConfig();
  const getError = useGetAxiosError<TAddAccountOTPForm>();
  const handleToastError = useHandleToastError();
  const handleFormError = useHandleFormError<TAddAccountOTPForm>();
  const { setStep, setFailInfo, phoneNumber, correlationId } =
    useAddAccountWizardContext();
  const {
    remainingTime: expirationCountdown,
    isPaused: isExpirationCountdownStopped,
  } = useCountdownTimer(EXPIRATION_TIME);
  const {
    remainingTime: resendCodeCountdown,
    resetTimer: resetResendCodeCountdown,
    isPaused: isResendCodeCountdownStopped,
  } = useCountdownTimer(RESEND_CODE_TIME);
  const { isLoading: isResendLoading, mutate: sendOTPMutate } =
    useAddAccountResendOTPMutation();
  const { isLoading: isConfirmOTPLoading, mutate: confirmOTPMutate } =
    useAddAccountConfirmOTPMutation();
  const [errorText, setErrorText] = useState('');
  const methods = useForm<TAddAccountOTPForm>({
    resolver: useResolver(),
  });

  // Если метод вернет объект, значит по этому объекту будет сделан переход на Fail шаг
  const prepareFailedInfo = useCallback(
    (error: unknown): TAddAccountFailInfo | undefined => {
      const { errorCode, errorTitle } = getError(error);
      if (errorCode === OTP_EXPIRED_CODE) {
        return {
          title: t('accounts:addAccountModal.failStep.expired'),
          description: errorTitle,
        };
      }
      if (errorCode === OTP_LOCKED_CODE) {
        return {
          title: t('accounts:addAccountModal.failStep.failed'),
          description: errorTitle,
        };
      }
      return undefined;
    },
    [getError, t],
  );

  // Если кончилось время, то отправляем на экран ошибки
  useEffect(() => {
    if (isExpirationCountdownStopped) {
      setFailInfo({
        title: t('accounts:addAccountModal.failStep.expired'),
        description: t('accounts:addAccountModal.failStep.otpTimeExpired'),
      });
      setStep('failed');
    }
  }, [isExpirationCountdownStopped, setFailInfo, setStep, t]);

  const handleSubmit = useMemo(
    () =>
      methods.handleSubmit((values) => {
        if (!correlationId) {
          return;
        }

        setErrorText('');
        confirmOTPMutate(
          {
            correlationId,
            otpCode: values.code,
          },
          {
            onSuccess: () => setStep('success'),
            onError: (e) => {
              const failedInfo = prepareFailedInfo(e);
              if (failedInfo) {
                setFailInfo(failedInfo);
                setStep('failed');
                return;
              }
              const unknownFieldErrors = handleFormError(
                e,
                methods,
                errorsFieldsMap,
              );
              if (unknownFieldErrors.length)
                setErrorText(unknownFieldErrors.join(' '));
            },
          },
        );
      }),
    [
      confirmOTPMutate,
      correlationId,
      handleFormError,
      methods,
      prepareFailedInfo,
      setFailInfo,
      setStep,
    ],
  );

  const handleResendCode = useCallback(() => {
    if (!correlationId) {
      return;
    }
    sendOTPMutate(
      { correlationId },
      {
        onSuccess: () => {
          resetResendCodeCountdown();
        },
        onError: (e) => {
          const failedInfo = prepareFailedInfo(e);
          if (failedInfo) {
            setFailInfo(failedInfo);
            setStep('failed');
            return;
          }
          // Если неизвестно как отрабатывать ошибку, то отображаем её через toast и даём возможность повторить
          handleToastError(e);
        },
      },
    );
  }, [
    correlationId,
    handleToastError,
    prepareFailedInfo,
    resetResendCodeCountdown,
    sendOTPMutate,
    setFailInfo,
    setStep,
  ]);

  if (!settings) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <ModalHeaderActions
        isShowBack
        onBack={() => {
          setStep('selectCurrency');
        }}
      />
      <BankModalTitle
        title={t('accounts:addAccountModal.otpStep.title')}
        description={[
          t('accounts:addAccountModal.otpStep.description'),
          <Trans
            i18nKey="accounts:addAccountModal.otpStep.smsDescription"
            values={{ phone: phoneNumber }}
            components={{
              phone: (
                <Text
                  as="span"
                  textStyle={TextStyles.Subtitle14Medium}
                  color="primary.500"
                />
              ),
            }}
          />,
        ]}
      />
      <BankModalBody>
        <VStack spacing={2} pt="1px">
          <OtpField
            autoFocus
            name="code"
            length={otpLength ?? settings.otpLength}
            onComplete={() => handleSubmit()}
            isDisabled={isExpirationCountdownStopped || isConfirmOTPLoading}
          />
          {errorText && <ApiErrorText>{errorText}</ApiErrorText>}
          <HStack w="100%" justifyContent="space-between">
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
              <Trans
                i18nKey="accounts:transferMoney.expirationDescription"
                values={{ expirationCountdown }}
                components={{
                  timer: (
                    <Text
                      as="span"
                      textStyle={TextStyles.Subtitle14Medium}
                      color="primary.500"
                    />
                  ),
                }}
              />
            </Text>
            <Button
              variant="link"
              color="brands.500"
              textStyle={TextStyles.Subtitle14Medium}
              _hover={{ underline: 'none' }}
              _active={{ color: 'brands.500' }}
              isDisabled={
                !isResendCodeCountdownStopped || isExpirationCountdownStopped
              }
              isLoading={isResendLoading}
              onClick={handleResendCode}
              data-testid="button_resend-code"
            >
              {isResendCodeCountdownStopped ? (
                t('accounts:addAccountModal.otpStep.resendCode')
              ) : (
                <Text
                  as="span"
                  textStyle={TextStyles.Subtitle14Medium}
                  color="primary.200"
                >
                  {t('accounts:addAccountModal.otpStep.resendCodeCountdown', {
                    countdown: resendCodeCountdown,
                  })}
                </Text>
              )}
            </Button>
          </HStack>
        </VStack>
      </BankModalBody>
    </FormProvider>
  );
};
