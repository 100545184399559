import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../state/api';

export const useBankAccountsSettingsQuery = () => {
  const api = useApi();

  return useQuery(
    ['settings', 'bankAccounts'],
    () => api.getBankAccountsSettings(),
    {
      staleTime: 60 * 1000,
    },
  );
};
