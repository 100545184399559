import { FloatingInputField } from '@payler/ui-components';
import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BankModalTitle,
  ModalHeaderActions,
} from '../../../components/BankModal/BankModal';
import {
  TTransferDetailsForm,
  useTransferWizardContext,
} from '../TransferWizard';
import { useAccountsQuery } from '../../../hooks/accounts/queries';
import { LoaderBox } from '../../../components/LoaderBox/LoaderBox';
import { usePermissions } from '../../../hooks/use-permissions';
import { TransferDetailsCommon } from './TransferDetailsCommon';
import { TransferDetailsSelf } from './TransferDetailsSelf';
import { Flex, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { debounce } from 'lodash';

export const TransferDetailsForm = () => {
  const { t } = useTranslation();
  const {
    initialStep,
    setStep,
    setTransferDetails,
    recipient,
    initialSenderAccount,
    senderAccount,
    setSenderAccount,
    isNewRecipientState,
    setMode,
    setAddRecipientStep,
    isSelfTransfer,
    setIsSelfTransfer,
    setReceiverAccount,
  } = useTransferWizardContext();

  const methods = useFormContext<TTransferDetailsForm>();
  const {
    reset,
    formState: { isDirty },
    trigger,
    watch,
    setValue,
  } = methods;

  const amount = watch('amount');
  useEffect(() => {
    if (isDirty && !!amount) {
      trigger('amount');
    }
  }, [senderAccount]);

  const { data: senderAccountsList } = useAccountsQuery({
    enabled: !senderAccount,
  });

  /** Устанавливает senderAccount, в зависимости от реципиента, отрабатывает в случае когда запуск трансфера происходит не со страницы аккаунта */
  useEffect(() => {
    if (!senderAccount) {
      const foundedSenderAccount = senderAccountsList?.find(
        (acc) =>
          acc.currency === recipient?.account?.currency?.toLowerCase() &&
          acc.allowOutgoingOperations,
      );
      setSenderAccount(foundedSenderAccount);
      setValue('senderAccountId', foundedSenderAccount?.id);
    }
  }, [
    recipient?.account?.currency,
    initialSenderAccount,
    senderAccountsList,
    setSenderAccount,
  ]);

  const handleBack = useCallback(() => {
    if (isNewRecipientState) {
      setAddRecipientStep('recipientInfo');
      setMode('addRecipient');
    } else {
      setTransferDetails(undefined);
      setReceiverAccount(undefined);
      setSenderAccount(initialSenderAccount);
      reset();
      setStep('select');
      setIsSelfTransfer(false);
    }
  }, [
    isNewRecipientState,
    setAddRecipientStep,
    setMode,
    setTransferDetails,
    reset,
    setStep,
    setIsSelfTransfer,
  ]);

  if (!senderAccount) {
    return <LoaderBox minH="312px" />;
  }

  return (
    <>
      <ModalHeaderActions
        isShowBack={initialStep === 'select'}
        onBack={handleBack}
      />
      <BankModalTitle title={t('accounts:transferMoney.transferDetails')} />
      {isSelfTransfer ? <TransferDetailsSelf /> : <TransferDetailsCommon />}
    </>
  );
};

export const TransferWizardAmountField = () => {
  const { t } = useTranslation();
  const { senderAccount, isSelfTransfer } = useTransferWizardContext();
  const { watch, trigger } = useFormContext<TTransferDetailsForm>();
  const amount = watch('amount');
  const { isGlobalAccounts } = usePermissions();

  const triggerValidation = useMemo(
    () =>
      debounce(() => {
        trigger(['files', 'description']);
      }, 300),
    [trigger],
  );

  useEffect(() => {
    return () => {
      triggerValidation.cancel();
    };
  }, []);

  const handleChange = useCallback(() => {
    if (!isSelfTransfer) {
      triggerValidation();
    }
  }, [isSelfTransfer, triggerValidation]);

  return (
    <FloatingInputField
      name="amount"
      label={t('accounts:transferMoney.amount')}
      variant="currency"
      autoComplete="off"
      currencySuffix={
        amount ? senderAccount?.currency.toUpperCase() : undefined
      }
      allowNegative={false}
      onChange={handleChange}
      isReadOnly={!isGlobalAccounts}
    />
  );
};

interface IDetailsCardProps {
  icon?: ReactElement;
  title?: string;
  subtitle?: string | null;
}
export const DetailsCard: FC<IDetailsCardProps & StackProps> = ({
  icon,
  title,
  subtitle,
  ...rest
}) => {
  return (
    <HStack
      w="100%"
      spacing={2}
      alignItems="start"
      p={2}
      borderRadius={1.5}
      {...rest}
    >
      <Flex alignItems="center">{icon}</Flex>
      <VStack spacing={0} alignItems="baseline">
        {title && (
          <Text textStyle={TextStyles.Subtitle14Medium} color="primary.500">
            {title}
          </Text>
        )}
        {subtitle && (
          <Text textStyle={TextStyles.Caption12Regular} color="primary.400">
            {subtitle}
          </Text>
        )}
      </VStack>
    </HStack>
  );
};
