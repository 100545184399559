import { CommonOtp } from './CommonOtp';
import { useTransferWizardContext } from './TransferWizard';

export const TransferOtp = ({ close }: { close: VoidFunction }) => {
  const { transferId, setStep } = useTransferWizardContext();
  const onSuccess = () => setStep('success');
  const onBack = () => setStep('info');
  return (
    <CommonOtp
      transferId={transferId}
      close={close}
      onSuccess={onSuccess}
      onBack={onBack}
      isOutgoingTransfer
    />
  );
};
