export const accounts = {
  allAccountsRestricted:
    'Access to all accounts is restricted. Please contact your account manager.',
  accountWarning: {
    restrictedOutgoing:
      'Your account is restricted. You cannot perform outgoing transfers. Please contact your Account Manager.',
    restrictedIncoming:
      'Your account is restricted. You cannot perform incoming transfers. Please contact your Account Manager.',
    restrictedAll:
      'Your account is restricted. You cannot perform incoming and outgoing transfers. Please contact your Account Manager.',
    paymentRequest:
      'There are insufficient funds on your balance to cover mandatory fees {{amount}}',
    incomingTransferPending: `Incoming transfer of <bold>{{amount}} {{currency}}</bold> is pending`,
  },
  allAccounts: 'All accounts',
  hidden: '{{number}} hidden',
  activity: 'Activity',
  seeAll: 'See all',
  addAccount: 'Add account',
  currenciesNumber: '{{number}} currencies',
  accountInfo: {
    sendMoney: 'Send money',
    topUp: 'Top up',
    statement: 'Statement',
    editName: 'Edit Name',
    exchange: 'Exchange',
    confirmation: 'Confirmation',
  },
  accountStatement: {
    title: 'Account Statement',
    description:
      'Please choose the date range to generate and download an account statement.',
    labels: {
      dateFrom: 'Date from',
      dateTo: 'Date to',
    },
    fieldNames: {
      dateFrom: 'dateFrom',
      dateTo: 'dateTo',
    },
  },
  accountEditName: {
    title: 'Edit account name',
    ariaLabel: 'Edit account',
  },
  accountsDrawer: {
    accounts: '{{number}} accounts',
    addAccount: 'Add account',
    ariaLabels: {
      closeDrawer: 'Close drawer',
      showOptions: 'Show options',
      enableSortingMode: 'Enable sorting mode',
      applySorting: 'Apply sorting',
    },
  },
  mobileMoreDrawer: {
    more: 'More',
    statement: 'Account statement',
    editName: 'Edit account name',
    confirmation: 'Confirmation',
  },
  transferMoney: {
    whoToPay: 'Who to pay',
    selectRecipient: 'Please select a recipient',
    newTransfer: 'New transfer',
    search: 'Search',
    recipientsNotAdded: 'No recipients have been added yet',
    noRecipientsDescription:
      'To continue, please click <b>“New transfer”</b> button',
    nothingFound: 'Nothing found',
    transferDetails: 'Transfer details',
    accountNotFound: 'Account not found',
    recipientAccountNotFound: 'Recipient account not found',
    transferDetailsDescription:
      'Please add the transfer details and proceed with the transfer.',
    description: 'Description',
    continue: 'Continue',
    confirmation: 'Confirmation',
    senderAccount: 'Sender account',
    receiverAccount: 'Receiver account',
    noSourceAccount: 'No source account',
    recipient: 'Recipient',
    iban: 'IBAN',
    accountNumber: 'Account Number',
    bic: 'BIC',
    bankName: 'Bank name',
    bankCountry: 'Bank country',
    address: 'Address',
    amount: 'Amount',
    fee: 'Fee',
    total: 'Total',
    transfer: 'Transfer',
    confirmationText:
      'Please check the transfer information and confirm the transfer.',
    transferIdNotFound: 'Transfer id not found',
    transferEnqueued: 'Transfer enqueued',
    dropzone: {
      title: 'Add confirmation document',
      description: 'Drag & Drop or choose file to upload',
    },
    documentWarning:
      'If the amount is equal to or greater than 15000, it is necessary to <bold>attach a supporting document</bold>',
    descriptionWarning:
      'If the amount is equal to or greater than 1000, it is necessary to <bold>fill the description</bold>',
    expirationDescription:
      'The code will expire in <timer>{{ expirationCountdown }} sec</timer>',
    resendCode: 'Resend Code',
    transferFee: 'Fee: {{ amount }} {{ currency }}',
    transferType: 'Transfer type',
    completeOnboardingProcess: 'Complete onboarding process',
    yourAccountIsCurrentlyNotActivated:
      "Your account is currently not activated. To unlock this functionality, kindly complete all the necessary steps, and we'll activate your account promptly",
    attachedDocuments: 'Attached documents',
    otpTitle: 'Paste code from SMS',
    otpDescription:
      'We’ve sent you an SMS with confirmation code to <phone>{{ phone }}</phone>. Please enter it below.',
    resendCodeCountdown: 'Resend code in {{ countdown }} sec',
    otpExpired:
      'Your OTP entry time has expired, and the payment process has been canceled',
    transferCanceled:
      'Sorry, the OTP entered is incorrect. Your payment has been canceled for security reasons. Please retry the payment process',
    exitOtpAlertTitle: 'Close window?',
    exitOtpAlertDescription:
      'Closing this window will result in the cancellation of your payment transaction. Are you sure you want to proceed?',
    saveRecipient: 'Save recipient',
    downloadPdf: 'Download PDF',
    downloading: 'Downloading...',
    transferToSelfTitle: 'Transfer to self',
    betweenAccounts: 'Between your accounts in the same currency',
    transferToNewRecipient: 'Transfer to new recipient',
    mainTransferMethod: 'Main transfer method',
    selfWarning: {
      title:
        "We couldn't find a corresponding account in the same currency for this transfer.",
      description:
        'Please ensure you have a second account in the same currency to proceed',
    },
    error: {
      title: 'Transfer cannot be completed',
      requisitesNotFound:
        "Sorry, we couldn't complete your transfer as your account is missing the necessary requisites. Please contact us at <emailLink>{{ email }}</emailLink> for technical support.",
    },
    selfTransfer: 'Self transfer',
  },
  topUp: {
    title: 'Add Money',
    subtitle:
      'Please use the account details displayed below to send funds to your Payler account.',
    details:
      'Use these details to receive <bold>{{ type }}</bold> transfers in <bold>{{ currency }}</bold>',
    noData: 'No account data',
    emptyRequisites: {
      message: 'Your account details are being created.',
      description: 'Please wait a moment.',
    },
    requisitesType: {
      swift: 'SWIFT',
      sepa: 'SEPA',
      internal: 'Internal',
    },
    requisitesName: {
      accountNumber: 'Account number',
      beneficiary: 'Beneficiary',
      intermediaryBank: 'Intermediary bank',
      iban: 'IBAN',
      bic: 'BIC',
    },
  },
  exchange: {
    selectStep: {
      title: 'Exchange',
      description:
        'Buy and Sell the relevant currency and store the funds into your account.',
      amount: 'Amount',
      youSpend: 'You spend',
      youGet: 'You get',
      exchangeRate: 'Exchange rate',
      notEnoughMoney: "You've entered more than you have in your account",
      exchangeDirection: '{{ senderCurrency }} → {{ receiverCurrency }}',
      exchangeRateError: 'Failed to get rate, please try again later',
    },
    confirmationStep: {
      title: 'Confirmation',
      description:
        'Please check the exchange information and confirm the transaction.',
      rate: 'Rate',
      youSpend: 'You spend',
      youGet: 'You get',
      noteWarning: 'Please note.',
      noteDescription:
        '<warning>{{ noteWarning }}</warning> We currently do not fix the exchange rate for currency exchange operations. During the exchange process, the exchange rate may change slightly. If this happens, the transaction will be performed according to the stock exchange. The payler fee will be deducted from the amount of debit.',
    },
    successTitle: 'Accepted for processing',
    successDescription:
      'We remind you that the exchange rate may change slightly during the execution of the operation. If this happens, the transaction will be performed according to the stock exchange.',
  },
  addAccountModal: {
    currencyStep: {
      title: 'Add account',
      description:
        'Please select the list of currencies for which you want to open',
    },
    otpStep: {
      title: 'Paste code from SMS',
      description:
        'In case of confirmation, an account in the selected currency will be created',
      smsDescription:
        'We’ve sent you an SMS with confirmation code to <phone>{{ phone }}</phone>. Please enter it below.',
      resendCode: 'Resend Code',
      resendCodeCountdown: 'Resend code in {{ countdown }} sec',
    },
    failStep: {
      expired: 'Expired',
      otpTimeExpired: 'Your OTP entry time has expired',
      failed: 'Failed',
    },
    accountAdded: 'Account added',
  },
};
