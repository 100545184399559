import { CommonOtp } from '../TransferModal/CommonOtp';
import { useCloseExchangeModal } from './ExchangeModal';
import { useExchangeWizardContext } from './ExchangeWizard';

export const ExchangeOtp = () => {
  const { transferId, setStep } = useExchangeWizardContext();
  const onSuccess = () => setStep('success');
  const onBack = () => setStep('confirmation');
  const close = useCloseExchangeModal();

  return (
    <CommonOtp
      transferId={transferId}
      close={close}
      onSuccess={onSuccess}
      onBack={onBack}
      isOutgoingTransfer={false}
    />
  );
};
